<template>
  <v-container class="wrap-container d-flex align-center justify-center" fluid>
    <v-card class="wrap-container--main pa-10" flat rounded="lg">
      <!-- Start: Title -->
      <v-card-title class="text-h5 font-weight-bold justify-center pa-0 mb-6">
        Đăng nhập hệ thống ThinkPro CMS
      </v-card-title>
      <!-- End: Title -->
      <!-- Start: Alert Error -->
      <v-alert
        class="mb-8"
        v-if="statusRequestUser.value === 'error-login'"
        text
        type="error"
      >
        {{ statusRequestUser.message }}
      </v-alert>
      <!-- End: Alert Error -->
      <!-- Start: Login Form -->
      <v-form ref="form" v-model="formValid" lazy-validation>
        <!-- Start: Username -->
        <v-text-field
          prepend-inner-icon="mdi-account-outline"
          v-model="user.username"
          v-mask="'##########'"
          :rules="[rules.required, rules.phoneRule]"
          label="Số điện thoại"
          outlined
          placeholder="Nhập số điện thoại"
          required
          @keypress.enter="login()"
        ></v-text-field>
        <!-- End: Username -->
        <!-- Start: Password -->
        <v-text-field
          class="my-2"
          prepend-inner-icon="mdi-lock-outline"
          v-model="user.password"
          :rules="[rules.required]"
          type="password"
          label="Mật khẩu"
          outlined
          placeholder="Nhập mật khẩu"
          required
          @keypress.enter="login()"
        ></v-text-field>
        <!-- End: Password -->
        <!-- Start: Login Btn -->
        <v-btn
          color="primary"
          class="mr-4 text-h6 font-weight-bold"
          block
          :disabled="!formValid"
          depressed
          x-large
          rounded
          @click="login"
        >
          ĐĂNG NHẬP
        </v-btn>
        <!-- Start: Login Btn -->
      </v-form>
      <!-- End: Login Form -->
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      formValid: true,
      rules: {
        required: value => !!value || "Bạn chưa nhập dữ liệu.",
        phoneRule: value => {
          if (this.regexPhone(value)) return true;
          else return "Số điện thoại không đúng.";
        }
      },
      user: {
        password: null,
        username: null
      }
    };
  },
  computed: {
    statusRequestUser() {
      return this.$store.getters["AUTHENTICATION/statusRequest"];
    }
  },
  methods: {
    async login() {
      await this.validate();

      if (this.formValid) {
        await this.$store.dispatch("AUTHENTICATION/login", {
          phone: this.user.username,
          password: this.user.password
        });
      }
    },
    regexPhone(phone) {
      const isVnMobilePhone = /^(0|\+84)(\s|\.)?((3[2-9])|(5[2689])|(7[06-9])|(8[1-9])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;
      return isVnMobilePhone.test(phone);
    },
    validate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap-container {
  background-color: rgba(39, 190, 206, 0.1);
  height: 100%;
  position: relative;
  &--main {
    background-color: #ffffff;
    max-width: 520px;
    min-width: 400px;
    width: 40%;
  }
}
</style>
